<template>
  <div class="header_wrap position-relative">
    <header
      class="header flex-between-center p-3 py-2 position-fixed bg-body top-0 start-50 w-100 translate-middle-x"
    >
      <div class="fs-6">
        <span class="text-body text-opacity-50" @click="ClickEvent()">
          <i class="me-3 far fa-chevron-left" v-if="link != '/home'"></i>
        </span>
        <span class="text-body">{{ title }}</span>
      </div>
      <div class="menu">
        <button
          class="btn border-0 position-relative"
          @click="$refs.PushPopup.pp = true"
        >
          <i class="fas fa-bell text-muted"></i>
          <span
            class="position-absolute badge rounded-pill bg-danger fs-px-10"
            v-if="total > 0"
            >{{ total }}</span
          >
        </button>
        <button
          class="btn border-0 ms-2 px-0"
          v-if="setting"
          @click="$router.push('/mypage/setting')"
        >
          <i class="far fa-cog p-1 text-body"></i>
        </button>
      </div>
    </header>
    <PushPopup ref="PushPopup" />
  </div>
</template>

<script>
const CryptoJS = require('crypto-js');
import PushPopup from '@/components/popup/Push.vue';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    setting: {
      type: Boolean,
      default: false,
    },
    alert: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      total: 0,
      login: this.$store.state.login,
    };
  },
  components: {
    PushPopup,
  },
  mounted() {
    if (this.login == true) {
      this.ShopNotice();
    }
  },
  methods: {
    ShopNotice() {
      const body = {};
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.$http.post('/shop/common/GetTotalPush', { req }).then((res) => {
        if (res.status == 200) {
          if (res.data.code == '200') {
            const e_body = res.data.body;
            const bytes = CryptoJS.AES.decrypt(
              e_body,
              process.env.VUE_APP_SECRET_TOKEN
            );
            const d_res = bytes.toString(CryptoJS.enc.Utf8);
            const body = JSON.parse(d_res);

            this.total = body.info.total;
          } else if (res.data.code == '9999') {
            this.$store.dispatch('SETLOGOUT').then(() => {
              window.location.href = '/signin';
            });
          }
        }
      });
    },

    ClickEvent() {
      if (this.link == '') {
        this.goBack();
      } else {
        this.goLink();
      }
    },

    goBack() {
      this.$router.go(-1);
    },
    goLink() {
      this.$router.push(this.link);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu .badge {
  top: -2px;
  right: -8px;
}

.header_wrap {
  padding-bottom: 55px;
  z-index: 100;

  .header {
    height: 55px;
    max-width: 768px;
  }
}
</style>
